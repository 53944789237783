<template>
  <div class="pa-2">
    <v-subheader>Utility</v-subheader>
    <v-divider class="pa-2"></v-divider>

    <CardList :cards="marketingUtility" identifier-key="key" :finishLoading="true" show-load-more-in="none"
      :cardsClickable="true">
      <template v-slot:card="{ card }">
        <v-card @click="handleListClick(card)" class="border-default mx-2 my-2 d-flex flex-column" dark
          :disabled="getCardDisabled(card) || isOnlyWheelOfFortune">
          <div class="text-center text-h6 pt-2 mx-6" @click.stop.prevent="handleListClick(card)">
            {{ card.text }}
          </div>
          <div style="height: 100px" class="flex-grow-1 d-flex justify-center align-center">
            <v-icon x-large @click.stop.prevent="handleListClick(card)">{{
              card.icon
            }}</v-icon>
          </div>
          <v-divider v-if="!!card.newRoute"></v-divider>
          <div class="d-flex justify-end" v-if="!!card.newRoute">
            <v-btn icon class="mr-2" @click.stop.prevent="handleListClick(card)">
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn icon class="mr-2" @click.stop.prevent="handlePlusClick(card)" :disabled="isLicenseBlocked">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
    </CardList>
    <v-subheader>Settings</v-subheader>
    <v-divider class="pa-2"></v-divider>
    <CardList :cards="marketingSetting" identifier-key="key" :finishLoading="true" show-load-more-in="none"
      :cardsClickable="true">
      <template v-slot:card="{ card }">
        <v-card @click="handleListClick(card)" class="border-default  mx-2 my-1 d-flex flex-column" dark
          :disabled="isOnlyWheelOfFortune">
          <div class="text-center text-h6 pt-2 mx-6" @click.stop.prevent="handleListClick(card)">
            {{ card.text }}
          </div>
          <div style="height: 100px" class="flex-grow-1 d-flex justify-center align-center">
            <v-icon x-large @click.stop.prevent="handleListClick(card)">{{
              card.icon
            }}</v-icon>
          </div>
        </v-card>
      </template>
    </CardList>

    <div id="free-space"></div>

    <StandardDialog v-model="dialogBarcode" title="Inserisci il badge" :dialog-height="null" dialog-max-width="500px">
      <ManualBarcodeInput v-model="operatorBarcode" scan-your-barcode-message="Immettere Codice"
        @confirm="askPermissionToGoToIntermediary">
      </ManualBarcodeInput>
    </StandardDialog>

    <SimplePopUp 
      v-model="dialogComingSoon" 
      alert-text="Coming soon..."
      :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '65vw' :
          $vuetify.breakpoint.xl ? '15vw' : 
          $vuetify.breakpoint.lg ? '25vw' : '35vw'"
    />
  </div>
</template>

<script>
import { CardList } from "likablehair-ui-components";
import marketingService from "@/services/marketing/marketing.service.js";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import operatorService from '@/services/operators/operators.service.js'
import StandardDialog from '@/components/common/StandardDialog.vue'
import CookieService from "@/services/cookies.service";
import UrlKeeper from '@/services/UrlKeeper.js'
import SimplePopUp from '@/components/common/SimplePopUp.vue'

export default {
  name: "MarketingHome",
  components: {
    CardList,
    ManualBarcodeInput,
    StandardDialog,
    SimplePopUp
  },
  data: function () {
    return {
      marketingList: [],
      operatorBarcode: undefined,
      dialogBarcode: false,
      currentUser: undefined,
      isLicenseBlocked: undefined,
      isOnlyWheelOfFortune: undefined,
      dialogComingSoon: false
    };
  },
  mounted() {
    this.loadMarketing();
  },
  methods: {
    loadMarketing() {
      marketingService.list().then(data => {
        this.marketingList = data;
      });
    },
    handleListClick(registry) {
      this.openDialogRoute(registry.listRoute);
    },
    handlePlusClick(registry) {
      this.openDialogRoute(registry.newRoute);
    },
    openDialogRoute(routeName) {
      if (routeName == 'intermediaryList') {
        this.dialogBarcode = true
      } else if (routeName == 'bookingSetting') {
        let form = document.createElement('form');
        form.target = '_blank';
        form.method = 'POST';
        form.action = UrlKeeper.getUrl('bookingSettings');

        let licenseUid = document.createElement('input');
        licenseUid.name = 'licenseUid';
        licenseUid.value = this.currentUser.licenses[0].uid;
        licenseUid.hidden = true;

        let authUid = document.createElement('input');
        authUid.name = 'authUid';
        authUid.value = CookieService.get('user-id');
        authUid.hidden = true;

        let authToken = document.createElement('input');
        authToken.name = 'authToken';
        authToken.value = CookieService.get('auth-token');
        authToken.hidden = true;

        form.appendChild(licenseUid);
        form.appendChild(authUid);
        form.appendChild(authToken);

        document.getElementById('free-space').innerHTML = '';
        document.getElementById('free-space').appendChild(form);
        form.submit();
      } else if (routeName == "wheelOfFortune") {
        let form = document.createElement('form');
        form.target = '_blank';
        form.method = 'POST';
        form.action = UrlKeeper.getUrl('wheelOfFortune');

        let licenseUid = document.createElement('input');
        licenseUid.name = 'licenseUid';
        licenseUid.value = this.currentUser.licenses[0].uid;
        licenseUid.hidden = true;

        let authUid = document.createElement('input');
        authUid.name = 'authUid';
        authUid.value = CookieService.get('user-id');
        authUid.hidden = true;

        let authToken = document.createElement('input');
        authToken.name = 'authToken';
        authToken.value = CookieService.get('auth-token');
        authToken.hidden = true;

        form.appendChild(licenseUid);
        form.appendChild(authUid);
        form.appendChild(authToken);

        document.getElementById('free-space').innerHTML = '';
        document.getElementById('free-space').appendChild(form);
        form.submit();
      } else if(routeName == 'editorialCalendar' || routeName == 'managementCalendar' || routeName == 'sendNewsletter' || routeName == 'sendEmail' || routeName == 'sendNews') {
        this.dialogComingSoon = true
      } else {
        this.$router.push({
          name: routeName
        });
      }
    },
    askPermissionToGoToIntermediary() {
      operatorService.canPerformOperation(this.operatorBarcode, "IntermediaryOperator").then((result) => {
        if (result) {
          this.$router.push({
            name: 'intermediaryList',
            params: {
              verified: true
            }
          });
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    getCardDisabled(card) {
      if (!!this.isLicenseBlocked) {
        switch (card.name) {
          case 'sendMassiveSms':
            return true
          default:
            return false
        }
      }

      return false
    }
  },
  computed: {
    marketingUtility() {
      return this.marketingList.filter(el => {
        if ((el.name == 'bookingVoucher') && !this.viewBooking.active)
          return false;
        if ((el.name == 'calendarMarketing') && !this.viewMarketingCalendar.active)
          return false;

        return [
          'promos',
          'intermediary',
          'news',
          'smsRegister',
          'sendMassiveSms',
          'bookingVoucher',
          'calendarMarketing',
          'editorialCalendar',
          'managementCalendar',
          'sendNewsletter',
          'sendEmail',
          'sendNews'
        ].includes(el.name)
      })
    },
    marketingSetting() {
      return this.marketingList.filter((el) => {
        if ((el.name == 'bookingSetting') && !this.viewBooking.active)
          return false;
        if ((el.name == 'smsModelPreset' || el.name == 'receiptModelPreset') && !this.viewMarketingCalendar.active)
          return false;
        if ((el.name == 'wheelOfFortune') && !this.viewWheelOfFortune.active)
          return false;

        return [
          "automationMarketing",
          "sms",
          "intermediarySetting",
          "bookingSetting",
          "smsModelPreset",
          "receiptModelPreset",
          "wheelOfFortune"
        ].includes(el.name);
      });
    },
  },
  currentUser: {
    bind: 'currentUser'
  },
  licenseSettings: {
    viewBooking: {
      bind: "viewBooking"
    },
    viewMarketingCalendar: {
      bind: "viewMarketingCalendar"
    },
    viewWheelOfFortune: {
      bind: "viewWheelOfFortune"
    }
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  },
  isOnlyWheelOfFortune: {
    bind: 'isOnlyWheelOfFortune'
  }
};
</script>
<style scoped>
.border-default {
  padding: 5px;
  border-radius: 10px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1);
  background-image: linear-gradient(rgb(138, 119, 226, 0.8), rgb(80, 191, 225, 0.8));
  width: 200px;
  height: 190px;
  background-color: white;
}

.border-default:hover {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1.05);
}
</style>